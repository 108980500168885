<template>
  <div>
    <img
      class="zdimg"
      v-if="type == 1"
      src="http://vehicle-repairs.oss-cn-hangzhou.aliyuncs.com/test/91051/1641305645634jiu1.jpg?Expires=1956665645&OSSAccessKeyId=LTAI4FhBMt2eaMxuMMSCECEm&Signature=YUfafJdGyEkZaQCd%2FDpgCwIoyvU%3D"
      alt
    />
    <img
      v-else-if="type == 2"
      class="zdimg"
      src="http://vehicle-repairs.oss-cn-hangzhou.aliyuncs.com/test/91051/1641305679272jiu2.jpg?Expires=1956665679&OSSAccessKeyId=LTAI4FhBMt2eaMxuMMSCECEm&Signature=crkKC3%2BBM2j%2FyS%2BnotwqS9tB%2Bjw%3D"
      alt
    />
    <img
      v-else-if="type == 3"
      class="zdimg"
      src="http://vehicle-repairs.oss-cn-hangzhou.aliyuncs.com/test/3494/164187002626177.jpg?Expires=1957230026&OSSAccessKeyId=LTAI4FhBMt2eaMxuMMSCECEm&Signature=LDZ9STuNIabGUWq6tphCh%2BF9ht8%3D"
      alt
    />
    <img
      v-else
      class="zdimg"
      src="http://vehicle-repairs.oss-cn-hangzhou.aliyuncs.com/test/3494/1641778297477%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20220110093058.jpg?Expires=1957138297&OSSAccessKeyId=LTAI4FhBMt2eaMxuMMSCECEm&Signature=Gm7O98uk%2B1i9LNrPy8kMGabXcB0%3D"
      alt
    />
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      type: this.$route.query.type
    };
  },

  methods: {},
  created() {}
};
</script>

<style lang="less" scoped>
.zdimg {
  width: 100%;
}
</style>